export enum ThemeList {
  DARK = "dark",
  Light = "light",
}

export enum LocalStorageKeys {
  ACCESS_TOKEN = "accessToken",
  THEME = "theme",
  USER = "user",
  ACCOUNT = 'account_type'
}
