import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import Routes from "./routes";
import { store } from "./store/store";
// import { useEffect } from "react";
// import { getTheme } from "./util/localStorage.util";
// import { ThemeList } from "./enum/enum";
// import "./assets/sass/dark.less";

function App() {
  // useEffect(() => {
  //   require("./assets/sass/dark.less")
  //   console.log(getTheme(), ThemeList.Light, getTheme() === ThemeList.Light)
  //   if (getTheme() && getTheme() === ThemeList.DARK) {
  //     console.log('light')
  //     require("./assets/sass/dark.less");
  //   } else {
  //     require("./assets/sass/main.less");
  //     console.log('dark')
  //   }
  // }, []);

  return (
    <BrowserRouter>
             <ErrorBoundary>
          <Provider store={store}>
            <Routes />
          </Provider>
        </ErrorBoundary>
      </BrowserRouter>
  );
}

export default App;
