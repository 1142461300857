import React from 'react';
import { getTheme } from "../util/localStorage.util";
import { ThemeList } from "../enum/enum";
import {lazy} from '../routes/lazy';

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const DarkTheme = lazy(() => import('./DarkTheme'));
const LightTheme = lazy(() => import('./LightTheme'));

const ThemeSelector: React.FC = ({ children }) => (
  <>
    {/* Conditionally render theme, based on the current client context */}
    <React.Suspense fallback={() => null}>
      {(getTheme() && getTheme() === ThemeList.Light)?<LightTheme />: <DarkTheme />}
    </React.Suspense>
    {/* Render children immediately! */}
    {children}
  </>
);

export default ThemeSelector;