export const handleError = (err: any, setServerError: any, setLoading: any) => {
  if (err.response.data.succeeded) {
    setServerError(err.response.data.data.errors[0]);
  } else {
    setServerError(err.response.data.message);
  }
  setLoading(false);
};

export const handleSuccess = (err: any, setSuccessHandle: any, setLoading: any) => {};

export const maxCharacterValidation = (_: any, value: any) => {
  if (!value || value.length < 30) {
    return Promise.resolve();
  }
  return Promise.reject('The field must contain less than 30 characters.');
};
