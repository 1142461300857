// import { IAuthReducer } from "./../../core/interface/auth.interface";
import * as adminActions from '../actions/adminActions';

const initState: any = {
  user: {},
  pdfDetails: {}
};

export const userReducer = (state = initState, action: any): any => {
  switch (action.type) {
    case adminActions.USER_DETAIL: {
      return {
        ...state,
        user: action.payload
      };
    }
    case adminActions.PDF_DETAILS: {
      return {
        ...state,
        fileDetails: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
