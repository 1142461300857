import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Menu, Typography, Button } from "antd";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { Link, useLocation } from "react-router-dom";
import { NavLinks } from "../interfaces";
import { sideNavList } from "./sideNavList";
import { getUserFromStorage } from "../util/localStorage.util";

function MenuList() {
  const location = useLocation();
  return (
    <Menu
      mode="vertical"
      defaultOpenKeys={["/" + location.pathname.split("/")[1]]}
      defaultSelectedKeys={[location.pathname]}
      theme="light"
    >
      {sideNavList.map((navLink: NavLinks) => {
        let storage = getUserFromStorage();
        if (storage) {
          let isAdmin = JSON.parse(storage).isAdmin;
          if (!isAdmin) {
            if (
              navLink.title === "Admin Management" ||
              navLink.title === "User Management"
            ) {
              return null;
            }
          }
        }

        if (navLink.children) {
          return (
            <SubMenu
              icon={navLink.icon}
              key={navLink.href}
              title={navLink.title}
            >
              {navLink.children.map((childrenLink: NavLinks) => (
                <Menu.Item key={childrenLink.href} icon={childrenLink.icon}>
                  <Link to={childrenLink.href}>{childrenLink.title}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={navLink.href} icon={navLink.icon}>
            <Link to={navLink.href}>{navLink.title}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default MenuList;
