import {
  ArrowLeftOutlined,
  DashboardOutlined,
  DotChartOutlined,
  FundOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Menu, Typography } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/meycologo.jpg";
import MenuList from "./Menu";

interface IProps {
  hideDrawer: () => void;
  visible: boolean;
  onClose: () => void;
}

const navdrawer = ({ visible, hideDrawer, onClose }: IProps) => {
  return (
    <div>
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div className="drawer__header">
          <div className="logo_inner">
            <img src={Logo} alt="logo" className="img-fluid" />
          </div>
          <Button onClick={hideDrawer} className="drawer__trigger">
            <ArrowLeftOutlined />
          </Button>
        </div>
        <div className="p-2">
          <Typography.Text strong>Meyco Order Portal</Typography.Text>
        </div>
        <MenuList />
      </Drawer>
    </div>
  );
};

export default navdrawer;
