import {
  PaperClipOutlined,
  UserOutlined,
  TeamOutlined,
  DownloadOutlined,
  // FundOutlined,
  TableOutlined
} from "@ant-design/icons";
import { NavLinks } from "../interfaces";

export const sideNavList: NavLinks[] = [
  // { href: "/", icon: <DashboardOutlined />, title: "Dashboard" },
  { href: "/", icon: <TableOutlined />, title: "Orders" },
  {
    href: "/material",
    icon: <PaperClipOutlined />,
    title: "Materials/PDFs",
    children: [
      // { href: "/order-material", icon: <TableOutlined />, title: "Order Materials" },
      { href: "/material-pdf", icon: <DownloadOutlined />, title: "Download PDFs" },
    ],
  },
  { href: "/admin", icon: <UserOutlined />, title: "Admin Management" },
  { href: "/user", icon: <TeamOutlined />, title: "User Management" },
  // { href: "/analytics", icon: <DotChartOutlined />, title: "Analytics" },
  // { href: "/fund", icon: <FundOutlined />, title: "Funds" },
  // { href: "/tables", icon: <TableOutlined />, title: "Tables" },
];
